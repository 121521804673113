




















































































































































































@import '~@/assets/styles/components/table';
.v-data-table thead tr th,
.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
  color: $colorPrimary !important;
}

.box-table {
  .v-data-table-header-mobile {
    display: none;
  }
}

.fab-cadastrar-usuario {
  position: fixed;
  z-index: 1;
  bottom: 25px;
  right: 5px;

  @media (min-width: 599px) {
    display: none !important;
  }
}

.modal-lista-caixa .v-data-table__wrapper table {
  min-width: 800px;
}
// .filtro-entities{
//   position: absolute;
//   z-index: 1;
//   top: -16.5px;
//   @media(max-width: 600px){
//     position: relative;
//     width: 100% !important;
//   }
// }
